import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Fab, Paper, Toolbar, TextField } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

import AdminPage from "../components/AdminPage";
import LocalFile from "../../components/LocalFile";

import { actions } from '../../services/stockPhotos';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import StockPhotoItem from "./StockPhotoItem";
import { pathToFileURL } from "url";
import { StockPhotoCategory } from "repix-common";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootStockPhotos: {
      },
      addIcon: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
      },
      photoGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }
    }
  ),
);

const StockPhotos: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stockPhotos = useSelector((state: RootState) => state.stockPhotos);

  const [ category, setCategory ] = useState<StockPhotoCategory>();

  useEffect(() => {
    if (typeof category === 'undefined' && stockPhotos.categories.length > 0) {
      setCategory(stockPhotos.categories[ 0 ]);
    }
  }, [ stockPhotos.categories.length ])

  useEffect(() => {
    if (!category) {
      dispatch(actions.loadCategories());
      return;
    }

    dispatch(actions.loadListLive(category.id));
  }, [ category ]);


  const onFileSelect = (file: File, src: string) => {
    console.log('onFileSelect', file);
    dispatch(actions.uploadPhoto(file, src));
  }

  const photos = category && stockPhotos.list[category.id] ? stockPhotos.list[category.id] : [];

  return (
    <AdminPage className={classes.rootStockPhotos} title="Stock Photos" loading={stockPhotos.listLoading}>
      <Paper variant="outlined">
        <Toolbar>
          <Autocomplete
            options={stockPhotos.categories}
            getOptionLabel={(option) => option.name}
            style={{ width: 200 }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            onChange={(e, newValue) => { setCategory(newValue) }}
            value={category || (null as any)}
            disableClearable
          />
        </Toolbar>
      </Paper>

      <div className={classes.photoGrid}>
        {
          photos.map(photo => (
            <StockPhotoItem
              key={photo.id}
              photo={photo}
              uploadProgress={stockPhotos.uploadProgress[ photo.id ]}
              cacheSrc={stockPhotos.localCache[ photo.id ]}
            />
          ))
        }
      </div>

      {/* <LocalFile className={classes.addIcon} onChange={onFileSelect} label="Add New Photo..." /> */}

    </AdminPage>
  );
};

export default StockPhotos;