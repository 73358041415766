import React from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import AdminPage from "./components/AdminPage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootUserImages: {
      },
    }
  ),
);

const UserImages: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  return (
    <AdminPage className={classes.rootUserImages} title="User Images">
      UserImages
    </AdminPage>
  );
};

export default UserImages;