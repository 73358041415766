import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Fab, Paper, Toolbar, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

import { useQueryParams, StringParam } from 'use-query-params';

import orderBy from 'lodash/orderBy';

import AdminPage from "../components/AdminPage";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../services/devices';
import { RootState } from "../../store";
import AddDeviceModal from "./AddDeviceModal";


import DeviceCard from './DeviceCard';
import { Device } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      addIcon: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
      },

      list: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },

      deviceItem: {
        margin: theme.spacing(1),
        cursor: 'grab',
      },
      deviceActive: {
        boxShadow: theme.shadows[ 10 ],
        cursor: 'grab',
      },
      formControl: {
        minWidth: 120,
      }
    }
  ),
);

const DevicesPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.devices);

  const [ addDialog, setAddDialog ] = useState(false);
  const [ activeDevice, setActiveDevice ] = useState<number>();

  const [ filter, setFilter ] = useQueryParams({
    type: StringParam,
    platform: StringParam,
    status: StringParam
  });

  const filterType = filter.type || 'all';
  const filterStatus = filter.status || 'all';
  const filterPlatform = filter.platform || 'all';



  const handleFilterChange = (name: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter({
      ...filter,
      [ name ]: event.target.value as string
    }, "replaceIn")
  };

  useEffect(() => {
    dispatch(actions.loadListLive());

    return () => {
      dispatch(actions.reset());
    }
  }, []);

  const toggleDialog = () => {
    setAddDialog(!addDialog);
  }


  const devices = orderBy(state.devices, [ 'platform', 'sort' ], [ 'asc', 'asc' ]).filter(device =>
    (filterPlatform === 'all' || filterPlatform === device.platform)
    && (filterType === 'all' || filterType === device.type)
    && (filterStatus === 'all' || filterStatus === device.status)
  );

  const SortableItem = SortableElement((props: { device: Device }) => (
    <DeviceCard device={props.device} className={classes.deviceItem} />
  ));


  const SortableList = SortableContainer(({ devices }: { devices: Device[] }) => {
    return (
      <div className={classes.list}>
        {devices.map((device, index) => (
          <SortableItem key={device.id} index={index} device={device} />
        ))}
      </div>
    );
  });

  return (
    <AdminPage title="Devices" loading={state.listLoading}>

      <Paper>
        <Toolbar>

          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel id="status-filter">Status</InputLabel>
            <Select
              autoWidth={false}
              labelId="status-filter"
              value={filterStatus}
              onChange={handleFilterChange('status')}
              labelWidth={40}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.space} />
          <div className={classes.space} />

          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel id="type-filter">Type</InputLabel>
            <Select
              autoWidth={false}
              labelId="type-filter"
              value={filterType}
              onChange={handleFilterChange('type')}
              labelWidth={40}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="tablet">Tablet</MenuItem>
              <MenuItem value="watch">Watch</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.space} />
          <div className={classes.space} />

          <FormControl variant="outlined" size="small" className={classes.formControl}>
            <InputLabel id="platform-filter">Platform</InputLabel>
            <Select
              autoWidth={false}
              labelId="platform-filter"
              value={filterPlatform}
              onChange={handleFilterChange('platform')}
              labelWidth={70}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="ios">iOS</MenuItem>
              <MenuItem value="android">Android</MenuItem>
            </Select>
          </FormControl>

        </Toolbar>
      </Paper>

      <Fab variant="extended" color="secondary" className={classes.addIcon} onClick={toggleDialog}>
        <AddIcon />
        Add
      </Fab>

      <AddDeviceModal open={addDialog} onClose={toggleDialog} />

      <SortableList
        pressDelay={300}
        axis="xy"
        devices={devices}
        shouldCancelStart={() => filterPlatform === 'all'}
        onSortEnd={(e) => {
          const newList = arrayMove(devices, e.oldIndex, e.newIndex);
          dispatch(actions.updateSort(newList));
        }}
        helperClass={classes.deviceActive}
      />
      {/* {
          devices.map(device => (
            <DeviceCard key={device.id} device={device} className={classes.deviceItem} />
            ))
          } */}
      {/* <div className={classes.list}>
      </div> */}

    </AdminPage>
  );
};

export default DevicesPage;