import React from "react";
import clsx from 'clsx';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, LinearProgress } from "@material-ui/core";
import { AdminAppBar, drawerWidth } from "./AdminLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      adminPage: {
        position: 'relative',
        minHeight: 'calc(100vh - 68px)',
        [ theme.breakpoints.down('sm') ]: {
          minHeight: 'calc(100vh - 60px)',
        },
      },
      spacing: {
        padding: theme.spacing(2),
      },
      loading: {
        height: 4,
        position: 'absolute',
        left: drawerWidth,
        right: 0,
      }
    }
  ),
);

interface Props {
  title?: string;
  className?: string;
  loading?: boolean;
  disableGutters?: boolean;
}

const AdminPage: React.FC<Props> = ({ title, children, className, loading, disableGutters }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.loading}>
        {
          loading ? <LinearProgress color="secondary" /> : null
        }
      </div>
      <div className={clsx(classes.adminPage, { [ classes.spacing ]: !disableGutters }, className)}>
        {
          title ? (
            <AdminAppBar>
              <Typography variant="h6" noWrap>
                {title}
              </Typography>
            </AdminAppBar>
          ) : null
        }
        {children}
      </div>
    </React.Fragment>
  );
};

export default AdminPage;