import React, { useState, useRef, useEffect, useContext } from "react";

import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  useTheme,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  ListItemText,
  List,
  ListItem,
  Hidden,
  Drawer,
  Portal,
  LinearProgress,
} from "@material-ui/core";

import MenuIcon from '@material-ui/icons/Menu';
import Logo from "../../components/Logo";
import RequireRole from "../../user/components/RequireRole";



export const drawerWidth = 200;


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      adminLayoutRoot: {
        display: 'flex',
      },
      drawer: {
        [ theme.breakpoints.up('md') ]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [ theme.breakpoints.up('md') ]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [ theme.breakpoints.up('md') ]: {
          display: 'none',
        },
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      layoutContent: {
        flexGrow: 1,
        // padding: theme.spacing(3),
      },
      drawerHeader: {
        ...theme.mixins.toolbar,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      logoLabel: {
        marginLeft: theme.spacing(1),
      },
      contextAppBar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }
    }
  ),
);

const Context = React.createContext({
  ref: null
});

const AdminLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ mobileOpen, setMobileOpen ] = useState(false);

  const ref = useRef(null);
  const [ refState, setRefState ] = useState(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setRefState(ref.current)
  }, []);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  }

  const drawer = (
    <div onClick={closeDrawer}>
      <div className={classes.drawerHeader}>
        <Logo linkTo="/admin/" variant="admin" size={130} />
      </div>
      <Divider />
      <List>

        <ListItem button component={Link} to="/admin/projects">
          <ListItemText primary="Projects" />
        </ListItem>
        {/* <ListItem button component={Link} to="/admin/user-images">
          <ListItemText primary="User Images" />
        </ListItem> */}
        <ListItem button component={Link} to="/admin/devices">
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button component={Link} to="/admin/stock-photos">
          <ListItemText primary="Stock Photos" />
        </ListItem>
        <ListItem button component={Link} to="/admin/design-elements">
          <ListItemText primary="Design Elements" />
        </ListItem>
        <ListItem button component={Link} to="/admin/design-templates">
          <ListItemText primary="Design Templates" />
        </ListItem>

        <Divider />
        <ListItem button component={Link} to="/">
          <ListItemText primary="Client Area" />
        </ListItem>
      </List>

    </div>
  );

  return (
    <Context.Provider value={{ ref: refState }}>
      <div className={classes.adminLayoutRoot}>
        <AppBar position="fixed" className={classes.appBar} elevation={1}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div ref={ref} className={classes.contextAppBar}>

            </div>
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.layoutContent}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </Context.Provider>
  );
};

export default AdminLayout;

export const AdminAppBar: React.FC = ({ children }) => {
  const context = useContext(Context);
  return (
    <Portal container={context.ref}>
      {children}
    </Portal>
  )
}