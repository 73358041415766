import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, TextField } from "@material-ui/core";
import { BackgroundDataCircle } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootBackgroundControlsRect: {
      },
      textField: {
        maxWidth: 174,
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
    }
  ),
);


interface Props {
  data: BackgroundDataCircle;
  onChange: (data: BackgroundDataCircle) => void;
  disabled?: boolean;
}

const BackgroundControlsRect: React.FC<Props> = ({ data, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootBackgroundControlsRect}>
      <Box display="flex" mb={3}>
        <TextField
          label="Top"
          variant="outlined"
          size="small"
          className={classes.textField}
          value={data.top}
          type="number"
          onChange={(e) => onChange({ ...data, top: parseFloat(e.target.value) })}
          disabled={disabled}
        />
        <div className={classes.spacer} />
        <TextField
          label="Left"
          variant="outlined"
          size="small"
          className={classes.textField}
          value={data.left}
          type="number"
          onChange={(e) => onChange({ ...data, left: parseFloat(e.target.value) })}
          disabled={disabled}
        />
      </Box>

      <Box display="flex" mb={10}>
        <TextField
          label="Border Radius"
          variant="outlined"
          size="small"
          className={classes.textField}
          value={data.radius}
          type="number"
          onChange={(e) => onChange({ ...data, radius: parseFloat(e.target.value) })}
          disabled={disabled}
        />
      </Box>
    </div>
  );
};

export default BackgroundControlsRect;