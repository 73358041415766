import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography } from "@material-ui/core";
import AdminPage from "./components/AdminPage";

import { actions } from '../services/stats';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      statsPaper: {
        padding: theme.spacing(2),
        width: '100%',
        maxWidth: 500,
      },
      title: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'block',
      },
      countBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
      },
      space: {
        height: theme.spacing(5),
      }
    }
  ),
);

const AdminHome: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const stats = useSelector((state: RootState) => state.stats);

  useEffect(() => {
    dispatch(actions.loadSummary());

    return () => {
      dispatch(actions.reset());
    }
  }, []);

  return (
    <AdminPage title="Dashboard" className={classes.root} loading={stats.loading}>
      <Paper className={classes.statsPaper}>
        <Typography variant="h6" className={classes.title}>Stats Summary</Typography>

        <div className={classes.countBox}>
          <Typography>Total users</Typography>
          <Typography>{stats.summary?.totalUsers}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Deleted users</Typography>
          <Typography>{stats.summary?.deletedUsers}</Typography>
        </div>
        
        <div className={classes.space}/>

        <div className={classes.countBox}>
          <Typography>Total exports</Typography>
          <Typography>{stats.summary?.totalExports}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Export errors</Typography>
          <Typography>{stats.summary?.exportErrors}</Typography>
        </div>

        <div className={classes.space}/>

        <div className={classes.countBox}>
          <Typography>Total projects</Typography>
          <Typography>{stats.summary?.totalProjects}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Deleted projects</Typography>
          <Typography>{stats.summary?.deletedProjects}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Total project designs</Typography>
          <Typography>{stats.summary?.totalProjectDesigns}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Deleted project designs</Typography>
          <Typography>{stats.summary?.deletedProjectDesigns}</Typography>
        </div>

        <div className={classes.space}/>

        <div className={classes.countBox}>
          <Typography>Total user fonts</Typography>
          <Typography>{stats.summary?.totalFonts}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Deleted user fonts</Typography>
          <Typography>{stats.summary?.deletedFonts}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>User font errors</Typography>
          <Typography>{stats.summary?.fontErrors}</Typography>
        </div>

        <div className={classes.space}/>

        <div className={classes.countBox}>
          <Typography>Total user images</Typography>
          <Typography>{stats.summary?.totalUserImages}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>Deleted user images</Typography>
          <Typography>{stats.summary?.deletedUserImages}</Typography>
        </div>
        <div className={classes.countBox}>
          <Typography>User image errors</Typography>
          <Typography>{stats.summary?.userImageErrors}</Typography>
        </div>

      </Paper>
    </AdminPage>
  );
};

export default AdminHome;