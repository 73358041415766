import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField, Box, FormControlLabel, Checkbox } from "@material-ui/core";

import Dialog from '../../components/Dialog';

import { actions } from '../../services/editDevice';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import find from 'lodash/find'
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      deviceImageDialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      img: {
        width: 400,
      },
      spacer: {
        width: theme.spacing(3),
      },
      imgContainer: {
        position: 'relative',
      },
      colorIndicator: {
        border: `1px solid ${theme.palette.divider}`,
        width: 100,
        height: 100,
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  imageId?: string;
  imageData?: string;
  imageFile?: File;
}

const DeviceImageDialog: React.FC<Props> = ({
  open,
  onClose,
  imageId,
  imageData,
  imageFile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.editDevice);

  const [ data, setData ] = useState<{ name: string, hex: string, }>({
    name: '',
    hex: '',
  });
  const [ imgSrc, setImgSrc ] = useState<string>();

  const [ errors, setErrors ] = useState<{ name: string, hex: string }>();

  const [ size, setSize ] = useState<{ width: number, height: number }>();

  const availableColors = useSelector((state: RootState) => state.devices.availableColors);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (imageId && state.data) {
      const image = find(state.images, { id: imageId });
      if (image) {
        setData({
          name: image.name,
          hex: image.color
        })
        setImgSrc(`${process.env.CDN_URL}/${image.path}`);
        setSize({ width: image.width, height: image.height });
      }
    }
    else {
      setData({
        name: '',
        hex: ''
      })
      setImgSrc(imageData);
      setSize(undefined);
    }
  }, [ open ]);

  const onSubmit = async () => {
    if (validate() && imageFile) {
      await dispatch(actions.addImage({
        name: data.name,
        color: data.hex,
        file: imageFile,
        src: imgSrc,
      }));
    }
    else if (imageId) {
      await dispatch(actions.updateImage({
        imgId: imageId,
        name: data.name,
        color: data.hex,
      }));
    }

    onClose();
  }

  const validate = () => {
    const _err: any = {}
    if (!data.name) {
      _err[ 'name' ] = 'Name required';
    }
    if (!data.hex) {
      _err[ 'hex' ] = 'HEX code required';
    }
    else if (data.hex[ 0 ] !== '#' || data.hex.length !== 7) {
      _err[ 'hex' ] = 'Invalid HEX code';
    }
    if (Object.keys(_err).length === 0) {
      setErrors(undefined);
      return true;
    }
    else {
      setErrors(_err);
      return false;
    }
  }

  let width;
  let height;
  if (size && size.width && size.height) {
    width = 400;
    height = width / (size.width / size.height);
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      cancelCallback={onClose}
      okCallback={onSubmit}
      okLabel="Save"
      inProgress={state.imageUpdateInProgress}
    >
      <div className={classes.deviceImageDialog}>
        <Box display="flex" mb={4}>

          <Box flex={1}>
            <TextField
              fullWidth
              variant="outlined"
              label="Name"
              helperText={errors?.name}
              error={Boolean(errors?.name)}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              value={data.name}
              disabled={state.imageUpdateInProgress}
              size="small"
            />
          </Box>

          <div className={classes.spacer} />

          <Box flex={1}>
            <TextField
              fullWidth
              variant="outlined"
              label="Color HEX"
              helperText={errors?.hex}
              error={Boolean(errors?.hex)}
              onChange={(e) => setData({ ...data, hex: e.target.value })}
              value={data.hex}
              disabled={state.imageUpdateInProgress}
              size="small"
            />
          </Box>

          <div className={classes.spacer} />

          <Box >
            <Autocomplete
              options={availableColors}
              getOptionLabel={(option) => option.name}
              style={{ width: 150 }}
              renderInput={(params) => <TextField {...params} label="Used Colors" variant="outlined" fullWidth size="small" />}
              onChange={(e, newValue) => {

                if (newValue) {
                  setData({
                    ...data,
                    hex: newValue.color,
                  })
                }
                else {
                  setData({
                    ...data,
                    hex: '#FFFFFF',
                  })
                }

              }}
            />
          </Box>

        </Box>

        {
          imgSrc ? (
            <div className={classes.imgContainer}>
              <img src={imgSrc} className={classes.img} style={{ width, height }} />
              <div className={classes.colorIndicator} style={{ backgroundColor: data.hex }} />
            </div>
          ) : null
        }
      </div>
    </Dialog>
  );
};

export default DeviceImageDialog;