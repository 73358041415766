import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Fab, TextField, Paper, Toolbar } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from "react-redux";

import AdminPage from "../components/AdminPage";
import { actions } from '../../services/designElements';
import { RootState } from "../../store";

import AddElementDialog from "./AddElementDialog";
import SvgItem from './DesignItem';
import { DesignElementCategory } from "repix-common";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootSvgElements: {
      },
      addButton: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
      },
      list: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      svgItem: {
        width: 100,
        height: 100,
        margin: theme.spacing(1),
      }
    }
  ),
);

const SvgElements: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const dispatch = useDispatch();

  const openDialog = () => {
    setDialogOpen(true);
  }
  const closeDialog = () => {
    setDialogOpen(false);
  }

  const svgElements = useSelector((state: RootState) => state.designElements);
  const [ category, setCategory ] = useState<DesignElementCategory>();

  useEffect(() => {
    if (typeof category === 'undefined' && svgElements.categories.length > 0) {
      setCategory(svgElements.categories[ 0 ]);
    }
  }, [ svgElements.categories.length ])

  useEffect(() => {
    if (!category) {
      dispatch(actions.loadCategories());
      return;
    }

    dispatch(actions.loadListLive(category.id));
  }, [ category ]);



  const elements = category && svgElements.list[ category.id ] ? svgElements.list[ category.id ] : [];


  return (
    <AdminPage className={classes.rootSvgElements} title="SVG elements" loading={svgElements.listLoading}>
      <Paper variant="outlined">
        <Toolbar>
          <Autocomplete
            options={svgElements.categories}
            getOptionLabel={(option) => option.name}
            style={{ width: 200 }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
            onChange={(e, newValue) => { setCategory(newValue) }}
            value={category || (null as any)}
            disableClearable
          />
        </Toolbar>
      </Paper>

      <div className={classes.list}>
        {
          elements.map(item => (
            <SvgItem key={item.id} svg={item} className={classes.svgItem} />
          ))
        }
      </div>

      {/* <Fab variant="extended" color="secondary" className={classes.addButton} onClick={openDialog}>
        <AddIcon />
        Add
      </Fab>
      <AddElementDialog open={dialogOpen} onClose={closeDialog} /> */}
    </AdminPage>
  );
};

export default SvgElements;