import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, Box, CircularProgress } from "@material-ui/core";

import orderBy from 'lodash/orderBy';

import { loadCanvases, loadProject } from '../../services/adminProjects';
import { loadUserFonts } from '../../services/fonts';
import { useDispatch} from "react-redux";

import { useStaticQuery, graphql } from "gatsby";
import { FontDataLight, ProjectData } from "repix-common";

import {
  CanvasProvider,
  Canvas,
  Canvases,
  CanvasActions,
  KeyboardControls,
  CanvasItem,
} from '../../components/Canvas';
import FilterToggles from "../../components/Canvas/FilterToggles";


const leftPanelWidth = 0;
const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootViewProject: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      leftPanel: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: leftPanelWidth,
        height: '100%',
        boxShadow: 'none',
        backgroundColor: theme.palette.background.paper,
        borderRight: '1px solid ' + theme.palette.divider,
        borderRadius: 0,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        position: 'absolute',
        left: leftPanelWidth,
        right: 0,
        top: 0,
        height: '100%',
      },
      canvasList: {
        position: 'absolute',
        left: 0,
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        padding: theme.spacing(1, 2, 4, 2),
        marginTop: 40,
      },
      paper: {
        overflow: 'hidden',
        borderRadius: 5,
        lineHeight: 0,
      },
      paperWrapper: {
        marginRight: theme.spacing(4),
        flex: '0 0 auto',
        '&:first-child': {
          marginLeft: theme.spacing(2),
        },
        '&:last-child': {
          marginRight: theme.spacing(2),
        },
        position: 'relative',
        verticalAlign: 'middle',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      filterToggles: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(4),
      },
    }
  ),
);

const fontsQuery = graphql`
  query {
    systemFonts: allFontData {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
    defaultFont: allFontData(filter: {default: {eq: true}}) {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
  }
`

interface FontsData {
  systemFonts: {
    nodes: FontDataLight[]
  },
  defaultFont: {
    nodes: FontDataLight[]
  }
}

interface Props {
  projectId: string;
}
const ViewProject: React.FC<Props> = ({ projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fontData = useStaticQuery<FontsData>(fontsQuery);

  const [ project, setProject ] = useState<ProjectData>();
  const [ canvases, setCanvases ] = useState<CanvasItem[]>([]);
  const [ fontList, setFontList ] = useState<FontDataLight[]>([]);
  const [ usedFontsLoading, setUsedFontsLoading ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    loadProjectData();
  }, [ projectId ]);


  const loadProjectData = async () => {
    setLoading(true);

    const _project = await loadProject(projectId);

    if (!_project) {
      return;
    }
    setProject(_project);

    const userFonts = await loadUserFonts(_project.user);


    const _fontList = orderBy([ ...fontData.systemFonts.nodes, ...userFonts ], [ 'type', 'fontFamily' ], [ 'desc', 'asc' ]);
    setFontList(_fontList as FontDataLight[]);



    const _canvases = await loadCanvases(_project.id);
    setCanvases(_canvases.canvases);
    if (_canvases.fonts.length > 0) {
      setUsedFontsLoading(true);
    }
    setLoading(false);
  }

  return (
    <div className={classes.rootViewProject}>
      {
        loading ? <CircularProgress /> : (
          <CanvasProvider
            canvases={canvases}
            onFontsLoaded={() => {
              setUsedFontsLoading(false);
            }}
            fonts={fontList}
            defaultFont={fontData.defaultFont.nodes[ 0 ]}
            sizeFilter={project?.sizeFilter}
            availableHeight={Math.max(Math.round(window.innerHeight - 400), 500)}
          >
            {/* <Paper className={classes.leftPanel}>
              <CanvasObjects />
            </Paper> */}
            <div className={classes.content}>
              {/* <ObjectProperties /> */}
              <div className={classes.filterToggles}>
                <FilterToggles />
              </div>
              <KeyboardControls />
              {/* <CopyFromType /> */}
              <div className={classes.canvasList}>
                {
                  usedFontsLoading ? (
                    <Box>
                      <Typography paragraph>loading fonts...</Typography>
                      <CircularProgress />
                    </Box>
                  ) : (
                      <React.Fragment>
                        <Canvases onRender={({ id, index, isActive, visible }) => {
                          return (
                            <div className={classes.paperWrapper} key={id} style={{ display: visible ? 'inline-flex' : 'none' }}>
                              {
                                visible ? <CanvasActions hidden={!isActive} /> : null
                              }
                              <Paper className={classes.paper} elevation={isActive ? 12 : 1}>
                                <Canvas id={id} index={index} disabled={!isActive} visible={visible} />
                              </Paper>
                            </div>
                          )
                        }} />
                      </React.Fragment>
                    )
                }
              </div>
            </div>
          </CanvasProvider>
        )
      }
    </div>
  );
};

export default ViewProject;