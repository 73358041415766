import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, ButtonBase, Typography, Box, Divider, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import Link from '../../components/Link';
import { TemplateData } from "repix-common";

import CanvasIcon from '@material-ui/icons/InsertDriveFileOutlined';


const cardWidth = 400;
const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTemplateCard: {
        width: cardWidth,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'hidden',
      },
      cardHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
      },

      cardTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },

      fullSpace: {
        flex: 1,
      },
      space: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      canvasIcon: {
        fontSize: 15,
      },
      thumbList: {
        width: cardWidth,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        overflow: 'scroll',
        padding: theme.spacing(1),
      },
      thumb: {
        flex: '0 0 auto',
        margin: theme.spacing(0, 1),
        borderRadius: theme.spacing(1),

        '&:first-child': {
          marginLeft: 0,
        }
      }
    }
  ),
);


interface Props {
  template: TemplateData;
  className?: string;
  onStatusChange: (id: string, status: 'active' | 'pending' | 'deleted') => void;
}

const TemplateCard: React.FC<Props> = ({ template, className, onStatusChange }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onStatusChange(template.id, event.target.value as any);
  };

  return (
    <Paper className={clsx(classes.rootTemplateCard, className)} elevation={1}>
      <div className={classes.cardHeader}>
        <div className={classes.cardTitle}>
          <Link variant="subtitle2" to={`/admin/design-templates/${template.id}`} color="secondary">{template.name}</Link>
          <div className={classes.fullSpace} />
          <Box display="flex" alignItems="center">
            <CanvasIcon className={classes.canvasIcon} color="action" />
            <div className={classes.space} />
            <Typography variant="caption">{template.designCount}</Typography>
          </Box>
        </div>
        
        <FormControl variant="outlined" size="small">
        <InputLabel id="template-status">Status</InputLabel>
        <Select
          labelId="template-status"
          value={template.status}
          onChange={handleChange}
          labelWidth={50}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="deleted">Deleted</MenuItem>
        </Select>
      </FormControl>
      </div>

      <div className={classes.thumbList}>
        {
          template.thumbs.map(thumb => {
            const height = 400;
            const width = (height / thumb.height) * thumb.width;
            return <img key={thumb.path} className={classes.thumb} src={`${process.env.CDN_URL}/${thumb.path}`} style={{ width, height }} />
          })
        }
      </div>
    </Paper>
  );
};

export default TemplateCard;