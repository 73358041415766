import React, { useState } from "react";
import { navigate } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography } from "@material-ui/core";

import PlatformPicker from '../../components/PlatformPicker';
import Dialog from '../../components/Dialog';
import { DevicePlatform, DeviceType } from "repix-common";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../services/editDevice';
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddDeviceModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const [ platform, setPlatform ] = useState<DevicePlatform>();
  const [ name, setName ] = useState('');
  const [ type, setType ] = useState('phone');
  const [ maker, setMaker ] = useState('');
  const dispatch = useDispatch();
  const inProgress = useSelector((state: RootState) => state.editDevice.loading);
  const [ error, setError ] = useState('');

  const onOK = async () => {
    const id = await dispatch(actions.createDevice({
      name,
      type: type as DeviceType,
      platform: platform as DevicePlatform,
      maker,
    }));

    if (id != null) {
      navigate(`/admin/devices/${id}`)
      onClose();
    }
    else {
      setError('Error creating a device');
    }
  }

  return (
    <Dialog
      // dividers
      maxWidth="xs"
      title="Add Device"
      open={open}
      cancelCallback={onClose}
      okCallback={onOK}
      okLabel="Continue"
      inProgress={inProgress}
      disabled={!name || !type || !platform || !maker}
    >
      <Typography color="error" align="center">{error}</Typography>
      <Box mt={2} mb={4}>
        <PlatformPicker value={platform} onChange={(value) => setPlatform(value)} />
      </Box>

      <Box mt={2} mb={2}>
        <FormControl fullWidth variant="outlined" color="primary" disabled={inProgress}>
          <InputLabel id="device-type">Type</InputLabel>
          <Select
            labelId="device-type"
            labelWidth={38}
            value={type}
            onChange={(e) => setType(e.target.value as string)}
          >
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="tablet">Tablet</MenuItem>
            <MenuItem value="watch">Watch</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={2} mb={2}>
        <TextField
          label="Maker/Manufacturer"
          variant="outlined"
          fullWidth
          color="primary"
          value={maker}
          onChange={(e) => setMaker(e.target.value)}
          disabled={inProgress}
        />
      </Box>

      <Box mt={2} mb={2}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          color="primary"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={inProgress}
        />
      </Box>


    </Dialog>
  );
};

export default AddDeviceModal;