import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Box, Button, Typography, Paper, IconButton, Tooltip } from "@material-ui/core";


import IconUnckecked from '@material-ui/icons/RadioButtonUncheckedOutlined';
import IconChecked from '@material-ui/icons/RadioButtonCheckedOutlined';
import IconDelete from '@material-ui/icons/Delete';

import { actions } from '../../services/editDevice';


import { Device, DeviceImage } from "repix-common";
import { useDispatch, useSelector } from "react-redux";
import { useDialog } from "../../components/DialogProvider";
import { RootState } from "../../store";

const imgWidth = 120;

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      deviceImageList: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
      },
      cover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.5,
      },
      imgItem: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
      },
      img: {
        width: imgWidth,
      },
      btn: {
        borderRadius: theme.spacing(0.5),
        overflow: 'hidden',
        margin: theme.spacing(1),
        position: 'relative',
      },
      colorBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      colorIndicator: {
        width: 20,
        height: 20,
        borderRadius: '50%',
      }
    }
  ),
);


interface Props {
  mainImage: string;
  images: DeviceImage[];
  onImageClick: (id: string) => void;
  disabled?: boolean;
}

const DeviceImageList: React.FC<Props> = ({ images, onImageClick, disabled, mainImage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useDialog();
  const localCache = useSelector((state: RootState) => state.editDevice.localCache);

  const onDelete = (img: DeviceImage) => {
    dialog.confirm({
      title: "Delete Image?",
      onConfirm: () => {
        dispatch(actions.deleteImage(img.device, img.id));
      }
    })
  }


  return (
    <div className={classes.deviceImageList}>
      {
        images.map((img, index) => {
          let width;
          let height;
          if (img.width && img.height) {
            width = imgWidth;
            height = width / (img.width / img.height);
          }
          const imgSrc = localCache[img.id] || `${process.env.CDN_URL}/${img.path}`;
          return (
            <Paper variant="outlined" className={classes.imgItem} key={img.path}>
              <Box textAlign="center">
                <Typography variant="caption">{img.status}</Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <ButtonBase className={classes.btn} onClick={() => onImageClick(img.id)}>
                  <img src={imgSrc} className={classes.img} style={{ width, height }} />
                  <div className={classes.colorBox}>
                    <div className={classes.colorIndicator} style={{ backgroundColor: img.color }} />
                    <Typography variant="caption">{img.name}</Typography>
                  </div>
                </ButtonBase>

                <Box display="flex" mt={1} alignItems="center">

                  <Tooltip title={mainImage === img.id ? 'Main Image' : 'Set as Main'}>
                    {
                      mainImage == img.id ? <IconChecked color="action" /> : (
                        <IconButton onClick={() => { dispatch(actions.setMainImage(img.id)) }}>
                          <IconUnckecked />
                        </IconButton>
                      )
                    }

                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton onClick={() => onDelete(img)}>
                      <IconDelete />
                    </IconButton>
                  </Tooltip>
                </Box>

              </Box>
            </Paper>
          );
        })
      }
      {
        disabled ? <div className={classes.cover} /> : null
      }
    </div>
  );
};

export default DeviceImageList;