import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Chip,
  CircularProgress
} from "@material-ui/core";
import IconDelete from '@material-ui/icons/Delete';

import { useDispatch } from "react-redux";
import { StockPhoto } from "repix-common";
import { useDialog } from "../../components/DialogProvider";
import { actions } from "../../services/stockPhotos";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootStockPhotoItem: {
        width: '100%',
        maxWidth: 350,
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      },
      media: {
        height: 0,
        paddingTop: '70%',
        position: 'relative',
      },
      content: {
        flex: 1,
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      avatar: {

      },
      spacer: {
        height: theme.spacing(3),
      },
      chip: {
        margin: theme.spacing(0.5),
      },
      actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(3),
      },
      progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }
    }
  ),
);

interface Props {
  photo: StockPhoto;
  cacheSrc?: string;
  uploadProgress?: number;
}

const StockPhotoItem: React.FC<Props> = ({ photo, cacheSrc, uploadProgress }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dialog = useDialog();


  const onDelete = () => {
    dialog.confirm({
      title: "Are you sure, you want to delete?",
      onConfirm: () => {
        dispatch(actions.deletePhoto(photo.id));
      }
    })
  }


  const src = cacheSrc || `${process.env.CDN_URL}/${(photo.small ? photo.small.path : photo.original.path)}`;

  return (
    <Card className={classes.rootStockPhotoItem}>
      <CardMedia
        className={classes.media}
        image={src}
      >
        {
          photo.status === 'pending' ? (
            <div className={classes.progress}>
              <CircularProgress variant={uploadProgress ? "static" : "indeterminate"} value={uploadProgress} />
            </div>
          ) : null}

      </CardMedia>
      <CardContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" component="p">
          Labels:
        </Typography>
        {
          photo.labels && photo.labels.map(label => <Chip label={label} key={label} className={classes.chip} />)
        }
      </CardContent>
      <CardActions className={classes.actions}>
        <Typography variant="caption">{photo.status}</Typography>
        <IconButton onClick={onDelete} disabled={photo.status !== 'active'}>
          <IconDelete />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default StockPhotoItem;