import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField } from "@material-ui/core";
import Dialog from '../../components/Dialog';

import { actions } from '../../services/templates';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootCreateProjectLayout: {
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      }
    }
  ),
);

interface Props {
  open: boolean;
  onCancel: () => void;
  onCreate: (projectId: string) => void;
}

const CreateTemplateDialog: React.FC<Props> = ({ open, onCancel, onCreate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ templateName, setTemplateName ] = useState('');

  const createProjectPending = useSelector((state: RootState) => state.projects.createProjectPending);

  const onNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTemplateName(e.target.value);
  }

  const okCallback = async () => {
    dispatch(actions.createTemplate({
      name: templateName,
      onCreate: (id) => {
        onCreate(id as string);
      }
    }));
  }

  const disabled = templateName === '';

  return (
    <Dialog
      open={open}
      cancelCallback={onCancel}
      okCallback={okCallback}
      title="Create Template"
      disabled={disabled}
      inProgress={createProjectPending}
    >
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <TextField
        label="Template Name"
        value={templateName}
        onChange={onNameChange}
        variant="outlined"
        color="secondary"
        fullWidth
        autoFocus
      />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
    </Dialog>
  );
};

export default CreateTemplateDialog;