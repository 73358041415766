import React from "react"
import { Router } from "@reach/router"

import AdminLayout from "../admin/components/AdminLayout";

import PrivateRoute from '../user/components/PrivateRoute';

import Home from '../admin/AdminHome';
import Devices from '../admin/Devices/Devices';
import EditDevice from '../admin/Devices/EditDevice';
import StockPhotos from '../admin/StockPhotos/StockPhotos';
import DesignElements from '../admin/DesignElements/DesignElements';
import DesignTemplates from '../admin/DesignTemplates/DesignTemplates';
import EditTemplate from '../admin/DesignTemplates/EditTemplate';

import Projects from '../admin/Projects/Projects';
import UserImages from '../admin/UserImages';

const AdminRoutes = () => (
  <AdminLayout>
    <Router>
      <Home path="/admin/" />
      <Devices path="/admin/devices/" />
      <EditDevice path="/admin/devices/:deviceId" />
      <StockPhotos path="/admin/stock-photos" />
      <DesignElements path="/admin/design-elements" />
      <DesignTemplates path="/admin/design-templates" />
      <EditTemplate path="/admin/design-templates/:templateId" />
      <Projects path="/admin/projects" />
      <UserImages path="/admin/user-images" />
    </Router>
  </AdminLayout>
)

const App = () => {
  return (
    <Router>
      <PrivateRoute component={AdminRoutes} admin default />
    </Router>
  )
}

export default App
