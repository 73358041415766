import React, { useEffect, useState } from "react";
import { fabric } from 'fabric';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";

import Dialog from '../../components/Dialog';
import { actions } from '../../services/editDevice';
import { Device, BackgroundDataRect, BackgroundDataCircle, DeviceBackgroundData } from "repix-common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import BackgroundControlsRect from './BackgroundControlsRect';
import BackgroundControlsCircle from './BackgroundControlsCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      backgroundDialog: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
      canvasContainer: {
        position: 'relative',
      },
      canvas: {
        position: 'absolute',
        top: 0,
        left: 0,
        transformOrigin: '0px 0px',
        border: `1px solid ${theme.palette.divider}`,
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        flex: 1,
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      textField: {
        maxWidth: 174,
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  device: Device;
}

let bgObject: fabric.Object | null;
let bgPrevType: 'rect' | 'circle' | null;

const BackgroundDialog: React.FC<Props> = ({ open, onClose, device }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.editDevice);

  const onSave = async () => {

    if (!background) {
      return;
    }

    await dispatch(actions.setBackground({
      background,
      screenSize,
    }));
    onClose();
  }

  const [ color, setColor ] = useState<string>(device.mainImage);
  const [ transparentOverlay, setTransparentOverlay ] = useState(true);

  const img = device.images[ color ];
  const imgUrl = `${process.env.CDN_URL}/${img.path}`;

  const height = Math.round(window.innerHeight - 250);
  const width = Math.round(height * (img.width / img.height));
  const scale = height / img.height;

  const [ background, setBackground ] = useState<BackgroundDataRect | BackgroundDataCircle>(device.background);

  const [ screenSize, setScreenSize ] = useState<{ width: number, height: number }>({
    width: Boolean(device.screenSize) ? device.screenSize.width : img.width,
    height: Boolean(device.screenSize) ? device.screenSize.height : img.height,
  });

  const [ canvas, setCanvas ] = useState<fabric.Canvas | null>();


  const onBackgroundTypeChange = (type: 'rect' | 'circle') => {
    if (type === 'rect') {
      const background: BackgroundDataRect = {
        type: 'rect',
        width: img.width,
        height: img.height,
        top: 0,
        left: 0,
        borderRadius: 5,
      }
      setBackground(background);
    }
    else if (type === 'circle') {
      const background: BackgroundDataCircle = {
        type: 'circle',
        top: 0,
        left: 0,
        radius: img.width / 2,
      }
      setBackground(background);
    }
  }


  if (!device.mainImage) {
    return null;
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    let _canvas: fabric.Canvas;

    requestAnimationFrame(() => {
      _canvas = new fabric.Canvas('bg-canvas', {
        selection: false,
      });


      _canvas.setZoom(scale);
      _canvas.setWidth(img.width * scale);
      _canvas.setHeight(img.height * scale);

      setCanvas(_canvas);
    })


    return () => {
      _canvas.dispose();
      setCanvas(null);
      bgPrevType = null;
    }

  }, [ open ]);

  useEffect(() => {
    if (!canvas) {
      return;
    }


    canvas.on('object:moved', (e) => {
      if (!bgObject) {
        return;
      }

      const data = bgObject.toObject();

      console.log('object:moved', data);

      setBackground({
        ...background,
        top: data.top as number,
        left: data.left as number,
      })
    });

    // canvas.on('object:scaled', (e) => {
    //   if (!bgObject) {
    //     return;
    //   }

    //   console.log('object:scaled');

    //   if (background.type === 'rect') {
    //     setBackground({
    //       ...background,
    //       width: bgObject.getScaledWidth(),
    //       height: bgObject.getScaledHeight(),
    //     } as BackgroundDataRect)
    //   }
    //   else if (background.type === 'circle') {
    //     setBackground({
    //       ...background,
    //       radius: bgObject.getScaledWidth()
    //     } as BackgroundDataCircle)
    //   }
    // });

    return () => {
      Object.keys((canvas as any).__eventListeners).forEach((prop) => {
        delete (canvas as any).__eventListeners[ prop ]
      })
    }

  }, [ canvas, background ])



  useEffect(() => {
    if (!open || !background || !canvas) {
      return;
    }

    requestAnimationFrame(() => {

      if (bgPrevType !== background.type) {
        bgObject && canvas?.remove(bgObject);
        bgObject = null;
      }

      bgPrevType = background.type;

      if (background.type === 'rect') {
        const bgData = background as BackgroundDataRect;

        if (!bgObject) {
          bgObject = new fabric.Rect({
            width: bgData.width,
            height: bgData.height,
            // scaleX: bgData.width / img.width,
            // scaleY: bgData.height / img.height,
            top: bgData.top,
            left: bgData.left,
            rx: bgData.borderRadius,
            ry: bgData.borderRadius,
            fill: '#ff0000',
            lockRotation: true,
            hasRotatingPoint: false,
            lockScalingX: true,
            lockScalingY: true,
          });
          canvas?.add(bgObject);
        }

        (bgObject as fabric.Rect).set({
          // scaleX: bgData.width / img.width,
          // scaleY: bgData.height / img.height,
          width: bgData.width,
          height: bgData.height,
          top: bgData.top,
          left: bgData.left,
          rx: bgData.borderRadius,
          ry: bgData.borderRadius,
        });
      }
      else if (background.type === 'circle') {
        const bgData = background as BackgroundDataCircle;

        if (!bgObject) {
          bgObject = new fabric.Circle({
            // scaleX: bgData.radius / img.width * 2,
            // scaleY: bgData.radius / img.width * 2,
            top: bgData.top,
            left: bgData.left,
            fill: '#ff0000',
            radius: bgData.radius,
            lockRotation: true,
            hasRotatingPoint: false,
            lockUniScaling: true,
            lockScalingX: true,
            lockScalingY: true,
          });
          canvas?.add(bgObject);
        }

        (bgObject as fabric.Circle).set({
          // scaleX: bgData.radius / img.width * 2,
          // scaleY: bgData.radius / img.width * 2,
          top: bgData.top,
          left: bgData.left,
          radius: bgData.radius,
        });
      }
      bgObject && bgObject.setCoords();
      canvas && canvas.renderAll();
    });

  }, [ open, background, canvas ]);

  useEffect(() => {
    if (!open) {
      return;
    }

    console.log('imgUrl', imgUrl);
    fabric.Image.fromURL(imgUrl, (img: fabric.Image) => {
      // img.scaleToWidth(width);
      console.log('img loaded');
      img.set({ opacity: transparentOverlay ? 0.6 : 1.0 })
      canvas && canvas.setOverlayImage(img, () => canvas?.renderAll());
    });

  }, [ open, color, transparentOverlay, canvas ])

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      cancelCallback={onClose}
      okCallback={onSave}
      okLabel="Save"
      title="Device Background"
      inProgress={state.loading}
    >
      <div className={classes.backgroundDialog}>
        <div className={classes.canvasContainer} style={{ width, height }}>
          <canvas id="bg-canvas" style={{ width, height }} className={classes.canvas} />
        </div>
        <div className={classes.toolbar}>
          <Box display="flex" mb={3}>
            <TextField
              label="Resolution Width"
              variant="outlined"
              size="small"
              className={classes.textField}
              value={screenSize.width}
              type="number"
              onChange={(e) => setScreenSize({ ...screenSize, width: parseFloat(e.target.value) })}
              disabled={state.loading}
            />
            <div className={classes.spacer} />
            <TextField
              label="Resolution Height"
              variant="outlined"
              size="small"
              className={classes.textField}
              value={screenSize.height}
              type="number"
              onChange={(e) => setScreenSize({ ...screenSize, height: parseFloat(e.target.value) })}
              disabled={state.loading}
            />
          </Box>

          <Box mt={2} mb={2}>
            <FormControl fullWidth variant="outlined" color="primary" disabled={state.loading}>
              <InputLabel id="bg-type">Bacground Type</InputLabel>
              <Select
                labelId="bg-type"
                labelWidth={120}
                value={background?.type || ''}
                onChange={(e) => onBackgroundTypeChange(e.target.value as any)}
              >
                <MenuItem value="rect">Rect</MenuItem>
                <MenuItem value="circle">Circle</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {
            background?.type === 'rect' ? (
              <BackgroundControlsRect data={background as BackgroundDataRect} onChange={(data) => setBackground(data)} />
            ) : null
          }
          {
            background?.type === 'circle' ? (
              <BackgroundControlsCircle data={background as BackgroundDataCircle} onChange={(data) => setBackground(data)} />
            ) : null
          }



          <Box display="flex" mb={3} flexDirection="column">
            <FormControl fullWidth variant="outlined" color="primary">
              <InputLabel id="device-image">Color</InputLabel>
              <Select
                labelId="device-image"
                labelWidth={38}
                value={color}
                onChange={(e) => { setColor(e.target.value as string) }}
              >
                {
                  Object.values(device.images).map(img => (
                    <MenuItem key={img.id} value={img.id}>{img.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={transparentOverlay}
                  onChange={(event) => {
                    setTransparentOverlay(event.target.checked)
                  }}
                  color="primary"
                />
              }
              label="Transparent Overlay"
            />
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default BackgroundDialog;