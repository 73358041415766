import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Typography } from "@material-ui/core";

import imgAndroid from './img/android.png';
import imgIos from './img/ios.png';

import { DevicePlatform } from 'repix-common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      platformPicker: {
        display: 'flex',
        justifyContent: 'center',
      },
      img: {
        width: 100,
      },
      btn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        // borderRadius: '50%',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        backgroundColor: 'transparent',
        transition: 'all 100ms',
        border: '3px solid transparent',
        '&:last-child': {
          marginRight: 0,
        }
      },
      selected: {
        border: '3px solid ' + theme.palette.primary.main,
      }
    }
  ),
);

interface Props {
  value?: DevicePlatform;
  onChange: (vaue: DevicePlatform) => void;
  size?: number;
  disabled?: boolean;
  labels?: boolean;
}

const PlatformPicker: React.FC<Props> = ({ value, onChange, size = 100, disabled, labels }) => {
  const classes = useStyles();


  return (
    <div className={classes.platformPicker}>
      <ButtonBase
        className={clsx(classes.btn, { [ classes.selected ]: value === 'android' })}
        onClick={() => { onChange('android') }}
        disabled={disabled}
      >
        {
          labels ? <Typography paragraph>Android</Typography> : null
        }
        <img src={imgAndroid} className={classes.img} style={{ width: size }} />
      </ButtonBase>

      <ButtonBase
        className={clsx(classes.btn, { [ classes.selected ]: value === 'ios' })}
        onClick={() => { onChange('ios') }}
        disabled={disabled}
      >
        {
          labels ? <Typography paragraph>iOS</Typography> : null
        }
        <img src={imgIos} className={classes.img} style={{ width: size }} />
      </ButtonBase>
    </div>
  );
};

export default PlatformPicker;