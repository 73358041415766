import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, CircularProgress } from "@material-ui/core";
import IconDelete from '@material-ui/icons/Close';

import { actions } from '../../services/designElements';

import { DesignElement } from "repix-common";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDialog } from "../../components/DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootSvgItem: {
        width: 100,
        height: 100,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
      deleteButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        padding: theme.spacing(0.5),
      },
      icon: {
        color: '#fff',
        fontSize: 18,
      }
    }
  ),
);

interface Props {
  svg: DesignElement;
  className?: string;
}

const SvgItem: React.FC<Props> = ({ svg, className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useDialog();

  const svgElements = useSelector((state: RootState) => state.designElements);

  const src = svgElements.localCache[ svg.id ] || `${process.env.CDN_URL}/${svg.asset ? svg.asset.path : svg.original.path}`;

  const onDelete = () => {
    dialog.confirm({
      title: "Are you sure, you want to delete?",
      onConfirm: () => {
        dispatch(actions.deleteElement(svg));
      }
    })
  }


  return (
    <div
      className={clsx(className, classes.rootSvgItem)}
      style={{ backgroundImage: `url(${src})` }}
    >
      {
        svg.status === 'pending' ? <CircularProgress /> : null
      }
      {
        Boolean(onDelete) ? (
          <ButtonBase className={classes.deleteButton} onClick={onDelete}>
            <IconDelete className={classes.icon} />
          </ButtonBase>
        ) : null
      }

    </div>
  );
};

export default SvgItem;