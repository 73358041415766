import React from "react";
import clsx from 'clsx';
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, ButtonBase, Typography, Box } from "@material-ui/core";
import orderBy from 'lodash/orderBy';

import { Device } from "repix-common";
import { PlatformLabels } from "../../components/PlatformPicker";

const imgWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      deviceCard: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 260,
      },
      top: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      btn: {
        padding: theme.spacing(2),
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      img: {
        width: 300,
        margin: theme.spacing(1, 1),
      },

      bottom: {
        padding: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },

      imgColor: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        border: `1px solid ${theme.palette.divider}`,
        marginRight: theme.spacing(1),
        '&:last-child': {
          marginRight: 0,
        }
      }

    }
  ),
);

interface Props {
  device: Device;
  className?: string;
  elevation?: number;
}

const DeviceCard: React.FC<Props> = ({ device, className}) => {
  const classes = useStyles();
  const img = device.mainImage ? device.images[ device.mainImage ] : null;

  const width = imgWidth;
  let height;
  if (img && img.width && img.height) {
    height = width / (img.width / img.height);
  }

  const images = orderBy(device.images, 'name', 'asc');

  return (
    <Paper className={clsx(classes.deviceCard, className)}>
      <div className={classes.top}>
        <Typography variant="body2">{device.name}</Typography>
        <Typography variant="caption">{PlatformLabels[ device.platform ]}</Typography>
      </div>
      <ButtonBase component={Link} to={`/admin/devices/${device.id}`} className={classes.btn}>
        {
          img ? <img className={classes.img} src={`${process.env.CDN_URL}/${img.path}`} style={{ width, height }} /> : null
        }
      </ButtonBase>
      <Box textAlign="center">
        <Typography variant="caption">{device.status}</Typography>
      </Box>
      <div className={classes.bottom}>
        {
          images.map(img => (
            <div key={img.id} className={classes.imgColor} style={{ backgroundColor: img.color }} />
          ))
        }
      </div>
    </Paper>
  );
};

export default DeviceCard;