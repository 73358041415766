import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography } from "@material-ui/core";
import AdminPage from "../components/AdminPage";

import { actions } from '../../services/editDevice';
import { actions as deviceListActions } from '../../services/devices';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import PlatformPicker from "../../components/PlatformPicker";
import { Device } from "repix-common";
import ImageFile from "../../components/LocalFile";
import DeviceImageDialog from './DeviceImageDialog';
import BackgroundDialog from './BackgroundDialog';
import DeviceImageList from "./DeviceImageList"; import arrayMove from 'array-move';



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      editDevice: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      paper: {
        width: '100%',
        maxWidth: 600,
        padding: theme.spacing(2),
      },
    }
  ),
);

interface Props extends RouteComponentProps {
  deviceId?: string;
}

const EditDevice: React.FC<Props> = ({ deviceId = '' }) => {
  const classes = useStyles();

  const [ device, setDevice ] = useState<Device>();
  const [ imageDialogOpen, setImageDialogOpen ] = useState(false);
  const [ bgDialogOpen, setBgDialogOpen ] = useState(false);

  const [ localFile, setLocalFile ] = useState<{ data: string; file: File }>();
  const [ imageId, setImageId ] = useState<string>();


  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.editDevice);

  useEffect(() => {
    dispatch(actions.loadDeviceData(deviceId));
    dispatch(deviceListActions.loadListLive());
    return () => {
      dispatch(actions.reset());
    }
  }, [ deviceId ]);

  const setData = (data: { [ key: string ]: any }) => {
    if (state.data) {
      dispatch(actions.setData({
        ...state.data,
        ...data,
      }))
    }
  }

  const closeDialog = () => {
    setImageDialogOpen(false);
    setLocalFile(undefined);
    setImageId(undefined);
  }

  const onImageSelect = (file: File, src: string) => {
    setLocalFile({
      data: src,
      file
    })
    setImageDialogOpen(true);
  }

  const onImageEdit = (imgId: string) => {
    setImageId(imgId);
    setImageDialogOpen(true);
  }

  const activeImages = state.images.filter(img => img.status !== 'deleted');
  const deletedImages = state.images.filter(img => img.status === 'deleted');

  const hasImages = state.data && state.data.images && Object.keys(state.data.images).length > 0;



  return (
    <AdminPage title={`Device: ${state.data?.name || ''}`} loading={state.loading} className={classes.editDevice}>
      <Paper className={classes.paper}>
        <Box mt={2} mb={4}>
          <PlatformPicker value={state.data?.platform} onChange={(value) => { setData({ platform: value }) }} labels disabled={state.loading} />
        </Box>

        <Box mt={2} mb={2}>
          <FormControl fullWidth variant="outlined" color="primary" disabled={state.loading}>
            <InputLabel id="device-status">Status</InputLabel>
            <Select
              labelId="device-status"
              labelWidth={38}
              value={state.data?.status || ''}
              onChange={(e) => { setData({ status: e.target.value as string }) }}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mt={2} mb={2}>
          <FormControl fullWidth variant="outlined" color="primary" disabled={state.loading}>
            <InputLabel id="device-type">Type</InputLabel>
            <Select
              labelId="device-type"
              labelWidth={38}
              value={state.data?.type || ''}
              onChange={(e) => { setData({ type: e.target.value as string }) }}
            >
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="tablet">Tablet</MenuItem>
              <MenuItem value="watch">Watch</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            color="primary"
            value={state.data?.name || ''}
            onChange={(e) => { setData({ name: e.target.value }) }}
            disabled={state.loading}
          />
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            label="Maker/Manufacturer"
            variant="outlined"
            fullWidth
            color="primary"
            value={state.data?.maker|| ''}
            onChange={(e) => { setData({ maker: e.target.value }) }}
            disabled={state.loading}
          />
        </Box>

        <Box mt={2} mb={5} textAlign="center">
          <Button onClick={() => dispatch(actions.updateDevice())} variant="contained" disableElevation>Save</Button>
        </Box>

        <Box mt={2} mb={2} display="flex" justifyContent="space-between">
          <ImageFile
            onChange={onImageSelect}
            disabled={state.loading}
            fileType="image/png"
            label="Add Image..."
          />
          <Button
            variant="outlined"
            onClick={() => setBgDialogOpen(true)}
            disabled={!state.data || !state.data.images || Object.keys(state.data.images).length === 0 || state.loading}
          >
            Set Background
          </Button>
        </Box>

        {
          activeImages.length > 0 ? (
            <Box mt={2} mb={2}>
              <Typography>Active</Typography>
              <DeviceImageList
                images={state.images.filter(img => img.status !== 'deleted')}
                mainImage={state.data?.mainImage || ''}
                onImageClick={onImageEdit}
                disabled={state.loading}
              />
            </Box>
          ) : null
        }

        {
          deletedImages.length > 0 ? (
            <Box mt={2} mb={2}>
              <Typography>Deleted</Typography>
              <DeviceImageList
                images={state.images.filter(img => img.status === 'deleted')}
                mainImage={state.data?.mainImage || ''}
                onImageClick={onImageEdit}
                disabled={state.loading}
              />
            </Box>
          ) : null
        }




      </Paper>
      <DeviceImageDialog
        open={imageDialogOpen}
        onClose={closeDialog}
        imageData={localFile?.data}
        imageFile={localFile?.file}
        imageId={imageId}
      />

      {
        state.data && hasImages ? <BackgroundDialog open={bgDialogOpen} onClose={() => setBgDialogOpen(false)} device={state.data} /> : null
      }
    </AdminPage>
  );
};

export default EditDevice;