import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, Box, Button, CircularProgress, lighten, InputBase } from "@material-ui/core";
import AdminPage from "../components/AdminPage";

import {
  CanvasProvider,
  Canvas,
  Canvases,
  CanvasObjects,
  ObjectProperties,
  CanvasActions,
  AddCanvasButton,
  KeyboardControls,
  CanvasItem
} from '../../components/Canvas';
import { AdminAppBar } from "../components/AdminLayout";
import FilterToggles from "../../components/Canvas/FilterToggles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import orderBy from "lodash/orderBy";

import ProgressButton from '../../components/ProgressButton';

import { actions, loadDesigns } from '../../services/templates';
import { useDialog } from "../../components/DialogProvider";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { FontDataLight } from "repix-common";

const leftPanelWidth = 320;
const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootCreateTemplate: {
      },
      leftPanel: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 320,
        height: '100%',
        boxShadow: 'none',
        backgroundColor: theme.palette.background.paper,
        borderRight: '1px solid ' + theme.palette.divider,
        borderRadius: 0,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        position: 'absolute',
        left: leftPanelWidth,
        right: 0,
        top: 0,
        height: '100%',
      },
      canvasList: {
        position: 'absolute',
        left: 0,
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        padding: theme.spacing(1, 2, 4, 2)
      },
      paper: {
        overflow: 'hidden',
        borderRadius: 5,
        lineHeight: 0,
      },
      paperWrapper: {
        marginRight: theme.spacing(4),
        flex: '0 0 auto',
        '&:first-child': {
          marginLeft: theme.spacing(2),
        },
        '&:last-child': {
          marginRight: theme.spacing(2),
        },
        position: 'relative',
        verticalAlign: 'middle',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      addButton: {

      },
      filterToggles: {
        position: 'absolute',
        top: theme.spacing(10),
        left: theme.spacing(4),
      },
      fullSpace: {
        flex: 1,
      },
      space: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      templateName: {
        width: '100%',
        maxWidth: 300,
      },
      inputTemplateName: {
        borderRadius: theme.spacing(.5),
        border: `1px solid transparent`,
        width: '100%',
        padding: theme.spacing(1),
        transition: 'all 200ms',
        '&:hover': {
          border: `1px solid ${theme.palette.primary.dark}`,
        },
        '&:focus': {
          border: `1px solid ${theme.palette.primary.dark}`,
          backgroundColor: lighten(theme.palette.primary.main, 0.2),
        }
      },
    }
  ),
);

const fontsQuery = graphql`
  query {
    systemFonts: allFontData {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
    defaultFont: allFontData(filter: {default: {eq: true}}) {
      nodes {
        css
        default
        fontFamily
        id
        thumb
        type
      }
    }
  }
`

interface FontsData {
  systemFonts: {
    nodes: FontDataLight[]
  },
  defaultFont: {
    nodes: FontDataLight[]
  }
}

interface Props extends RouteComponentProps {
  templateId?: string;
}

const EditTemplate: React.FC<Props> = ({ templateId }) => {

  const classes = useStyles();
  const dialog = useDialog();

  const dispatch = useDispatch();

  const [ canvasesLoading, setCanvasesLoading ] = useState(false);
  const [ fontsLoading, setFontsLoading ] = useState(false);
  const [ canvases, setCanvases ] = useState<CanvasItem[]>([]);

  const [ templateName, setTemplateName ] = useState('');

  const systemFonts = useStaticQuery<FontsData>(fontsQuery);

  
  const state = useSelector((state: RootState) => state.templates);

  const template = state.templates.find(item => item.id === templateId);

  useEffect(() => {
    dispatch(actions.loadListLive());
  }, []);

  const onNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTemplateName(e.target.value);
  }

  const onRename = () => {
    if (!template || templateName === template.name) {
      return;
    }

    dialog.confirm({
      title: 'Rename template?',
      onConfirm: () => {
        dispatch(actions.renameTemplate(template.id, templateName));
      },
      onCancel: () => {
        setTemplateName(template.name);
      }
    })
  }


  useEffect(() => {
    if (!template) {
      return;
    }

    setTemplateName(template.name);
  }, [ template ])


  const loadData = async () => {
    if (!templateId) {
      return;
    }

    setCanvasesLoading(true);
    const result = await loadDesigns(templateId, [ 'active', 'pending' ]);
    setCanvases(result.designs);
    if (result.fonts.length > 0) {
      setFontsLoading(true);
    }
    setCanvasesLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [ templateId ]);



  const deleteTemplate = () => {
    dialog.confirm({
      title: `Are you sure, you want to delete this template?`,
      onConfirm: () => {
        dispatch(actions.deleteTemplate(templateId as string));;
        navigate('/admin/design-templates');
      }
    })
  }

  const onCanvasAdd = (canvas: CanvasItem) => {
    if (!template) {
      return;
    }
    dispatch(actions.addDesign(canvas));
  }

  const onCanvasUpdate = (canvas: CanvasItem) => {
    if (!template) {
      return;
    }

    dispatch(actions.updateDesign(canvas));
  }

  const onCanvasDelete = (id: string) => {
    dispatch(actions.deleteDesign({ id } as CanvasItem));
  }

  const onFontsLoaded = () => {
    console.log('fonts done loading');
    setFontsLoading(false);
  }

  const saveData = () => {
    dispatch(actions.saveData(templateId as string))
  }

  const loading = state.listLoading || canvasesLoading;


  const fontList = orderBy(systemFonts.systemFonts.nodes, 'fontFamily', 'asc');

  const hasPendingChanges = Object.keys(state.pendingUpdates).length > 0;

  const sizeFilter = canvases.length > 0 ? {
    width: canvases[ 0 ].width,
    height: canvases[ 0 ].height,
  } : undefined;

  return (
    <AdminPage loading={loading}>
      {
        loading || !template ? null : (
          <React.Fragment>
            <AdminAppBar>
              <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">

                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle2">Template: </Typography>
                  <div className={classes.space} />
                  <InputBase value={templateName} onChange={onNameChange} onBlur={onRename} classes={{ input: classes.inputTemplateName, root: classes.templateName }} />
                </Box>

                <div>
                  <Button onClick={deleteTemplate}>Delete</Button>
                  <ProgressButton onClick={saveData} disabled={!hasPendingChanges || state.savePending} loading={state.savePending}>Save</ProgressButton>
                </div>
              </Box>
            </AdminAppBar>
            <CanvasProvider
              canvases={canvases}
              fonts={fontList}
              onFontsLoaded={onFontsLoaded}
              onCanvasAdd={onCanvasAdd}
              onCanvasUpdate={onCanvasUpdate}
              onCanvasDelete={onCanvasDelete}
              sizeFilter={sizeFilter}
              defaultFont={systemFonts.defaultFont.nodes[0]}
              availableHeight={Math.max(Math.round(window.innerHeight - 400), 500)}
            >
              <Paper className={classes.leftPanel}>
                <CanvasObjects />
              </Paper>
              <div className={classes.content}>
                <ObjectProperties />
                <KeyboardControls />
                <div className={classes.filterToggles}>
                  <FilterToggles />
                </div>
                <div className={classes.canvasList}>
                  {
                    fontsLoading ? (
                      <Box>
                        <Typography paragraph>loading fonts...</Typography>
                        <CircularProgress />
                      </Box>
                    ) : (
                        <React.Fragment>
                          <Canvases onRender={({ id, index, isActive, visible }) => (
                            <div className={classes.paperWrapper} key={id}>
                              {
                                visible ? <CanvasActions hidden={!isActive} /> : null
                              }
                              <Paper className={classes.paper} elevation={isActive ? 12 : 1}>
                                <Canvas id={id} index={index} disabled={!isActive} visible={visible} />
                              </Paper>
                            </div>
                          )} />
                          <AddCanvasButton className={classes.addButton} />
                        </React.Fragment>
                      )
                  }
                </div>
              </div>
            </CanvasProvider>
          </React.Fragment>
        )
      }
    </AdminPage>
  );
};

export default EditTemplate;