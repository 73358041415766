import React, { useEffect } from "react";
import clsx from 'clsx';
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ListItem, ListItemText, List, Button, ButtonBase, Typography } from "@material-ui/core";
import AdminPage from "../components/AdminPage";
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import ProgressButton from '../../components/ProgressButton';

import { actions } from '../../services/adminProjects';
import { useDispatch, useSelector } from "react-redux";
import orderBy from 'lodash/orderBy';
import { RootState } from "../../store";

import { ProjectData } from "repix-common";
import ViewProject from './ViewProject';

const listWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootProjectsPage: {
        display: 'flex',
      },
      list: {
        width: listWidth,
        borderRight: `1px solid ${theme.palette.divider}`
      },
      projectView: {
        flex: 1,
        position: 'relative',
      },
      projectItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
      },

      projectItemSelected: {
        backgroundColor: theme.palette.divider,
      },
      thumbList: {
        overflowX: 'auto',
        display: 'flex',
        width: listWidth,
      },
      thumb: {
        height: 124,
        flex: '0 0 auto',
        marginRight: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
      }
    }
  ),
);

const Item: React.FC<ListChildComponentProps> = ({ index, style, data }) => {
  const classes = useStyles();

  const project = data.list[ index ] as ProjectData;
  const currentProject = data.selected as string;

  const dispatch = useDispatch();

  const loadingMore = useSelector((state: RootState) => state.adminProjects.listLoadingMore);

  const [ params, setParamas ] = useQueryParams({
    project: StringParam,
  })

  const onLoadMore = () => {
    dispatch(actions.loadMore());
  }


  if (!project) {
    return <ProgressButton style={style} loading={loadingMore} onClick={onLoadMore}>Load more...</ProgressButton>
  }

  const onClick = async () => {
    setParamas({
      ...params,
      project: project.id,
    }, 'replaceIn');
  }

  const thumbs = orderBy(project.thumbs, 'sort', 'asc');

  return (
    <ButtonBase style={style} onClick={onClick} className={clsx(classes.projectItem, { [ classes.projectItemSelected ]: currentProject === project.id })}>
      <Typography noWrap variant="body2">{project.name}</Typography>
      <div className={classes.thumbList}>
        {
          thumbs.map(thumb => (
            <img key={thumb.path} src={`${process.env.CDN_URL}/${thumb.path}`} className={classes.thumb} />
          ))
        }
      </div>
    </ButtonBase>
  )
}

const ProjectsPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const projects = useSelector((state: RootState) => state.adminProjects);

  const [ params, setParamas ] = useQueryParams({
    page: NumberParam,
    project: StringParam,
  })

  useEffect(() => {
    dispatch(actions.loadList());
  }, []);

  return (
    <AdminPage className={classes.rootProjectsPage} title="Projects" loading={projects.listLoading} disableGutters>
      <div className={classes.list}>
        <List>
          <FixedSizeList
            height={window ? window.innerHeight - 80 : 0}
            itemCount={projects.list.length + 1}
            itemSize={160}
            width={listWidth}
            itemData={{
              list: projects.list,
              selected: params.project,
            }}
          >
            {Item}
          </FixedSizeList>
        </List>
      </div>
      <div className={classes.projectView}>
        {
          params.project ? <ViewProject projectId={params.project} /> : null
        }
      </div>
      {/* <div className={classes.list}>
        {
          projects.list.map(project => <ProjectCard key={project.id} project={project} />)
        }
      </div>

      <ProgressButton loading={false}>Load more...</ProgressButton> */}

    </AdminPage>
  );
};

export default ProjectsPage;