import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Fab } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

import AdminPage from "../components/AdminPage";

import { actions } from '../../services/templates';

import CreateTemplateDialog from './CreateTemplateDialog';
import TemplateCard from './TemplateCard';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootDesignTemplates: {
      },
      addButton: {
        position: 'fixed',
        right: theme.spacing(4),
        bottom: theme.spacing(4),
      },
      list: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: theme.spacing(2),
      },
      templateCard: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }
    }
  ),
);

const DesignTemplates: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.templates);

  const [ createDialogOpen, setCreateDialogOpen ] = useState(false);

  useEffect(() => {
    dispatch(actions.loadListLive());
  }, []);

  const onStatusChange = (id: string, status: string) => {
    dispatch(actions.setTemplateStatus(id, status));
  }

  const onTemplateCreate = (id: string) => {
    navigate(`/admin/design-templates/${id}`);
  }

  return (
    <AdminPage title="Design Templates" loading={state.listLoading}>
      <Fab
        variant="extended"
        color="secondary"
        className={classes.addButton}
        onClick={() => setCreateDialogOpen(true)}
      >
        <AddIcon />
        New Template
      </Fab>

      <CreateTemplateDialog
        open={createDialogOpen}
        onCancel={() => setCreateDialogOpen(false)}
        onCreate={onTemplateCreate}
      />

      <div className={classes.list}>
        {
          state.templates.map(template => <TemplateCard onStatusChange={onStatusChange} key={template.id} template={template} className={classes.templateCard} />)
        }
      </div>
    </AdminPage>
  );
};

export default DesignTemplates;